<template>
  <Card
    :radius="12"
    elevation
    link
    :tiled="tiled"
    :squared-thumbnail="true"
    @click="$emit('click', $event)">
    <a
      :href="href ? href : false"
      :style="!isLinked ? 'pointer-events: none' : ''"
      v-ripple="isLinked">
      <div class="c-card__inner">
        <div v-if="src" class="c-card__img">
          <img :src="src" :alt="title" />
        </div>

        <div class="c-card__content">
          <div v-if="title" class="c-card__title">
            {{ title }}
          </div>

          <div v-if="subtitle" class="c-card__subtitle">
            <Icon v-if="contentType === 'join'" name="pin" />{{ subtitle }}
          </div>

          <div v-if="pros || tag || schedules" class="c-card__bottom">
            <template v-if="contentType === 'join'">
              <div v-if="schedules" class="c-card__bottom__period">
                <span>{{ period }} </span>
              </div>
            </template>

            <div v-if="pros" class="c-card__bottom__pro">
              <ProAvatar
                :size="32"
                :src="pros[0]['image_url']"
                alt=""
                :is-favorite="pros[0]['is_favorite']"
                :is-bordered="false" />
              <span class="c-card__bottom__pro-name">
                {{ pros[0]['name'] }}
              </span>
            </div>

            <div v-else>
              <Tag v-if="tag" :tag="tag" />
            </div>

            <!-- <Incentive v-if="reward" :reward="reward" /> -->
          </div>
        </div>
      </div>
    </a>
  </Card>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
import { useDate } from '@/composables/useDate';
const { localizeDateTime, localizeTime, isSame } = useDate();

export default defineComponent({
  props: {
    href: {
      type: String,
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    tag: {
      type: String,
      default: ''
    },

    pros: {
      type: Array,
      default: null
    },

    reward: {
      type: [Object, Array],
      default: undefined
    },

    schedules: {
      type: [Object, Array, undefined],
      default: undefined
    },

    tiled: {
      type: Boolean,
      default: false
    },

    contentType: {
      type: String,
      default: ''
    }
  },

  setup: (props) => {
    const isLinked = computed(() => props.href && props.href.length > 0);
    const start = computed(() => props.schedules?.start_at);
    const end = computed(() => props.schedules?.end_at);
    const startDateTime = computed(() => localizeDateTime(start.value));
    const endDateTime = computed(() => localizeDateTime(end.value));
    const endTime = computed(() => localizeTime(end.value));

    // 開催期間
    const period = computed(() => {
      if (!startDateTime.value || !endDateTime.value) return;

      return isSame(start, end)
        ? startDateTime.value + ' 〜 ' + endTime.value
        : startDateTime.value + ' 〜 ' + endDateTime.value;
    });

    return {
      isLinked,
      period,
      start,
      end
    };
  }
});
</script>
