var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Card",
    {
      attrs: {
        radius: 12,
        elevation: "",
        link: "",
        tiled: _vm.tiled,
        "squared-thumbnail": true,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c(
        "a",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple",
              value: _vm.isLinked,
              expression: "isLinked",
            },
          ],
          style: !_vm.isLinked ? "pointer-events: none" : "",
          attrs: { href: _vm.href ? _vm.href : false },
        },
        [
          _c("div", { staticClass: "c-card__inner" }, [
            _vm.src
              ? _c("div", { staticClass: "c-card__img" }, [
                  _c("img", { attrs: { src: _vm.src, alt: _vm.title } }),
                ])
              : _vm._e(),
            _c("div", { staticClass: "c-card__content" }, [
              _vm.title
                ? _c("div", { staticClass: "c-card__title" }, [
                    _vm._v(" " + _vm._s(_vm.title) + " "),
                  ])
                : _vm._e(),
              _vm.subtitle
                ? _c(
                    "div",
                    { staticClass: "c-card__subtitle" },
                    [
                      _vm.contentType === "join"
                        ? _c("Icon", { attrs: { name: "pin" } })
                        : _vm._e(),
                      _vm._v(_vm._s(_vm.subtitle) + " "),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.pros || _vm.tag || _vm.schedules
                ? _c(
                    "div",
                    { staticClass: "c-card__bottom" },
                    [
                      _vm.contentType === "join"
                        ? [
                            _vm.schedules
                              ? _c(
                                  "div",
                                  { staticClass: "c-card__bottom__period" },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.period) + " "),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        : _vm._e(),
                      _vm.pros
                        ? _c(
                            "div",
                            { staticClass: "c-card__bottom__pro" },
                            [
                              _c("ProAvatar", {
                                attrs: {
                                  size: 32,
                                  src: _vm.pros[0]["image_url"],
                                  alt: "",
                                  "is-favorite": _vm.pros[0]["is_favorite"],
                                  "is-bordered": false,
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "c-card__bottom__pro-name" },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.pros[0]["name"]) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _vm.tag
                                ? _c("Tag", { attrs: { tag: _vm.tag } })
                                : _vm._e(),
                            ],
                            1
                          ),
                    ],
                    2
                  )
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }